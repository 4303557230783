.Menu-banner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/baanner/menu.jpg");
  height: 80vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: shrink 4s infinite alternate ease-in-out; */
}
.MenuBanner_Txt{
 margin-top: 100px;
}

.MenuBanner_Txt h1{
  font-size: 50px;
  font-family: var(---header);
  color: #fff;
  text-align: center;
}
.MenuBanner_Txt p{
  text-align: center;
  color: #fff;
  font-size: 24px;
} 

@media (max-width: 990px) {
  .MenuBanner_Txt{
    margin-top: 70px;
  }
  .MenuBanner_Txt h2{
    font-size: 24px;
    font-family: var(---HeadFont);
    color: #fff;
    text-align: center;
  }
  .MenuBanner_Txt p{
    text-align: center;
    color: #fff;
    font-size: 16px;
  } 
}
@media (max-width: 576px) {
  .Menu-banner {
    height: 50vh;
  }
}


/* ============= Menu Section =========== */
.menu-Cards{
  border: 1px solid gray;
  box-shadow: 0 0 7px gray;
  border-radius: 5px;
}
.menu-images{
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 300px;
}
.menu-images img{
  width: 100%;
  height: 100%;
  min-height:200px;
  max-height:300px;
}
.menu-desc{
  padding: 0px 20px;
}

.menuTimes{
  border: 1px solid var(---primaryColor);
  padding: 20px;
  box-shadow: 0 0 5px gray;
  border-radius: 5px;
  height: 340px;
}
.menuTimes h4{
  color: var(---primaryColor);
  font-family: var(---HeadFont);
  font-weight: 600;
  text-align: center;
}

.menuTimes h6{
  color: var(---SecondColor);
  font-family: var(---HeadFont);
  font-weight: 600;
}

.menuTimes ul{
  margin: 0px;
  padding: 0px;
}

.menuTimes ul li{
  font-size: 18px;
  font-family: var(---HeadFont);
}
.menuTimes ul li i{
  font-size: 14px;
  color: var(---primaryColor);
  padding-right: 10px;
}

.foodTime p{
  margin-top:10px;
  font-size:20px;
  color: var(---primaryColor);
  font-weight: 600;
}

/* ============= new Menu ========= */

    
.menucards{
  background-color: white;
  cursor: pointer;
  transition: all 0.5s;
  color: black;
  height: 100%;
  max-height: 500px;
  overflow: hidden;
  box-shadow: 0 0 4px gray;
  border-radius: 5px;
}
.menuImg{
  height: 250px;
}
.menuImg img {
  width: 100%;
  height: 100%;
}
.menucards h5{
font-family: var(---HeadFont);
margin: 10px 0px 10px 0px;
}
.menucards .text1 ul li{
list-style: disc !important;
}

.scrollText {
height: 180px;
overflow-y: scroll;
}

#style-3::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
width: 6px;
background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
background-color:var(---primaryColor);
}

@media (max-width: 990px) {
.menucards{
  background-color: white;
  cursor: pointer;
  transition: all 0.5s;
  color: black;
  height: 100%;
  max-height: 700px;
  overflow: hidden;
  box-shadow: 0 0 4px gray;
  border-radius: 5px;
}
.menuImg{
  height:300px;
}
}
@media (max-width: 550px) {
.menucards{
  background-color: white;
  cursor: pointer;
  transition: all 0.5s;
  color: black;
  height: 100%;
  max-height: 600px;
  overflow: hidden;
  box-shadow: 0 0 4px gray;
  border-radius: 5px;
}
.menuImg{
  height:200px;
}
}

/* ------------ book --------------- */

.Menu-Section-3 {
/* border: 1px solid red; */
background: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6)), url("../img/food/food5.jpg");
background-attachment: fixed;
padding: 10vh 0px;
background-size: 100% 100%;
}

.Menu-Section-3-con {
/* border: 1px solid blue; */
padding: 20px;
}

.book {
/* border: 1px solid blue; */
transition: opacity 0.4s 0.2s;
display: flex;
justify-content: center;
}

/* p{
  margin-top: 8vw;
  text-align: center;
  font-size: 5vw;
  color: #000000;
} */
.page {
width: 30vw;
height: 44vw;
background-color: #111111;
float: left;
margin-bottom: 0.5em;
background: left top no-repeat;
background-size: cover;
}

.page img {
height: 100%;
width: 100%;
}

.page:nth-child(even) {
clear: both;
}

.book {
perspective: 250vw;
}

.book .pages {
width: 100%;
height: 44vw;
position: relative;
transform-style: preserve-3d;
backface-visibility: hidden;
border-radius: 4px;
/*box-shadow: 0 0 0 1px #e3dfd8;*/
}

.book .page {
float: none;
clear: none;
margin: 0;
position: absolute;
top: 0;
width:50%;
height: 44vw;
transform-origin: 0 0;
transition: transform 1.4s;
backface-visibility: hidden;
transform-style: preserve-3d;
cursor: pointer;
user-select: none;
background-color: #f0f0f0;
}

.book .page:before {
content: '';
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
background: rgba(0, 0, 0, 0);
transition: background 0.7s;
z-index: 2;
}

.book .page:nth-child(odd) {
pointer-events: all;
transform: rotateY(0deg);
right: 0;
border-radius: 0 4px 4px 0;
background-image: linear-gradient(to right, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 0) 10%);
}

.book .page:nth-child(odd):hover {
transform: rotateY(-15deg);
}

.book .page:nth-child(odd):hover:before {
background: rgba(0, 0, 0, 0.03);
}

.book .page:nth-child(odd):before {
background: rgba(0, 0, 0, 0);
}

.book .page:nth-child(even) {
pointer-events: none;
transform: rotateY(180deg);
transform-origin: 100% 0;
left: 0;
border-radius: 4px 0 0 4px;
border-color: black;
background-image: linear-gradient(to left, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 10%);
}

.book .page:nth-child(even):before {
background: rgba(0, 0, 0, 0.2);
}

.book .page.grabbing {
transition: none;
}

.book .page.flipped:nth-child(odd) {
pointer-events: none;
transform: rotateY(-180deg);
}

.book .page.flipped:nth-child(odd):before {
background: rgba(0, 0, 0, 0.2);
}

.book .page.flipped:nth-child(even) {
pointer-events: all;
transform: rotateY(0deg);
}

.book .page.flipped:nth-child(even):hover {
transform: rotateY(15deg);
}

.book .page.flipped:nth-child(even):hover:before {
background: rgba(0, 0, 0, 0.03);
}

.book .page.flipped:nth-child(even):before {
background: rgba(0, 0, 0, 0);
}

