.About-banner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/baanner/about.jpg");
  height: 80vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: shrink 4s infinite alternate ease-in-out; */
}
.AboutBanner-Txt {
  margin-top: 100px;
}

.AboutBanner-Txt h1 {
  font-size: 50px;
  font-family: var(---header);
  color: #fff;
  text-align: center;
}
.AboutBanner-Txt p {
  text-align: center;
  color: #fff;
  font-size: 24px;
}

@media (max-width: 990px) {
  .AboutBanner_Txt {
    margin-top: 40px;
  }
  .AboutBanner-Txt h2 {
    font-size: 24px;
    font-family: var(---HeadFont);
    color: #fff;
    text-align: center;
  }
  .AboutBanner-Txt p {
    color: #fff;
    padding: 0px 10px 0px 10px;
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .About-banner {
    height: 50vh;
  }
}

/* ========== Counter Section =========== */
.about-counter {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/baanner/contact.jpeg");
  height: 100vh;
  background-size:cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
}

@media (max-width: 990px) {
  .about-counter {
    height: auto;
  }
}

.about-counter .row .resortBtn{
  display: flex;
  justify-content: center;
}

/* ============ testimonal ========= */
.tst-img {
  width: 75px;
  height: 75px;
}
.tst-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

/* ========= Our Stories =========== */
.story-timeline {
  position: relative;
}

.story-timeline::after {
  content: "";
  position: absolute;
  background: var(---SecondColor);
  width: 2px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.story-circle-start {
  position: absolute;
  background: #fff;
  width: 20px;
  height: 20px;
  left: 50%;
  top: 0;
  border: 2px solid var(---SecondColor);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: 1;
}
.story-img-shape {
  position: absolute;
  width: 100px;
  right: 0px;
  bottom: 70px;
}

.story-left-content .story-content {
  margin-right: 40px;
  padding: 40px;
  text-align: right;
}

.story-right-content .story-content {
  margin-left: 40px;
  padding: 40px;
}

.story-content span {
  font-weight: 500;
  color: var(---primaryColor);
  margin: 5px 0;
  display: block;
}
.story-content h3 {
  font-family: var(---HeadFont);
  color: var(---primaryColor);
  font-weight: 600;
  font-size: 24px;
}

.story-timeline .row {
  position: relative;
}

.story-icon {
  position: absolute;
  background: var(---SecondColor);
  color: #fff;
  font-size: 30px;
  width: 60px;
  height: 60px;
  line-height: 0px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.story-right-img {
  width: 100%;
  max-width: 170px;
  margin-left: 60px;
}
.story-right-img img {
  width: 100%;
  border: 2px solid var(---primaryColor);
  /* border-radius: 50%; */
}

.story-left-img {
  width: 100%;
  max-width: 170px;
  margin-left: auto;
  margin-right: 60px;
}
.story-left-img img {
  width: 100%;
  border: 2px solid var(---primaryColor);
  /* border-radius: 50%; */
}

@media all and (max-width: 991px) {
  .story-timeline::after {
    display: none;
  }
  .story-circle-start,
  .story-icon {
    display: none;
  }
  .story-left-content .story-content {
    margin-right: 0px;
    padding: 0px;
    text-align: unset;
  }
  .story-timeline .row {
    background: #edf3f8;
    margin-bottom: 25px;
    padding: 50px 30px;
  }
  .story-timeline .row:last-child {
    display: none;
  }
  .story-right-content .story-content {
    margin-left: 0px;
    padding: 0px;
  }
  .story-left-content {
    margin-bottom: 25px;
  }
  .story-right-content {
    margin-top: 25px;
  }
  .story-left-img {
    width: 100%;
    max-width: 170px;
    margin-left: auto;
    margin-right: auto;
  }
  .story-right-img {
    width: 100%;
    max-width: 170px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media all and (max-width: 750px) {
  .story-timeline .row {
    background: #edf3f8;
    margin-bottom: 25px;
    padding: 20px 10px;
  }
}

/* ============ our-story Odd ======= */
.our-story .our-story-detail {
  padding: 15px 0px;
}
.our-story .row {
  margin-top: 62px;
  margin-bottom: 62px;
  position: relative;
}
.our-story {
  position: relative;
  z-index: 0;
}
.our-story:after {
  content: "";
  position: absolute;
  width: 2px;
  background-color:var(---primaryColor);
  height: 100%;
  z-index: 0;
}
.our-story:after {
  left: 50%;
  top: 0px;
}

.notification-animate:after {
  content: "";
  position: absolute;
  background:var(---primaryColor);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 4;
}
.our-story .row:nth-child(odd) {
  text-align: left;
}
.our-story .row:nth-child(odd) .our-story-detail {
  margin-left: 50px;
}
.our-story .row:nth-child(odd) .notification-animate:before {
  top: 38px;
  right: 50%;
}
.our-story .row:nth-child(odd) .notification-animate:after {
  top: 44px;
  right: 50%;
}
.our-story .row:nth-child(odd) .our-story-pic-block:before {
  content: "";
  position: absolute;
  transform: rotate(90deg);
  height: 100px;
  width: 2px;
  background-color:var(---primaryColor);
  top: 0px;
  z-index: -1;
}
.our-story .row:nth-child(odd) .our-story-pic-block:before {
  right: 55%;
}
.our-story .row:nth-child(odd) .our-story-pic-block:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  border: 2px solid var(---primaryColor);
  background-color: #fff;
  z-index: 3;
}
.our-story .row:nth-child(odd) .our-story-pic-block:after {
  right: 49%;
  top: 35px;
}

@media only screen and (max-width: 992px) {
  .our-story .row:nth-child(odd) .our-story-pic-block:before {
    right: 56%;
  }
  .our-story .row:nth-child(odd) .our-story-pic-block:after {
    right: 48%;
  }
  .our-story .row:nth-child(odd) .notification-animate:after {
    right: 49%;
  }
}
@media only screen and (max-width: 767px) {
  .our-story .row {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  .our-story .row:nth-child(odd) .our-story-pic {
    margin-right: 0px;
    margin-left: 50px;
  }
  .our-story:after {
    left: 20px;
  }

  .our-story .row:nth-child(odd) .our-story-pic-block:after {
    left: 20px;
    right: auto;
  }

  .our-story .row:nth-child(odd) .our-story-pic-block:before {
    left: 80px;
  }
  .our-story .row:nth-child(odd) .notification-animate:before {
    right: auto;
    left: 22px;
  }
  .our-story .row:nth-child(odd) .notification-animate:after {
    right: auto;
    left: 30px;
  }
  .story-left-img {
    width: 100%;
    max-width: 170px;
    margin-left:50px;
    margin-right: auto;
  }
}

/* ============ our-story Even ======= */
.our-story .row:nth-child(even) {
  text-align: right;
}
.our-story .row:nth-child(even) .our-story-detail {
  margin-right: 50px;
}
.our-story .row:nth-child(even) .notification-animate:before {
  top: 38px;
  left: 50%;
}
.our-story .row:nth-child(even) .notification-animate:after {
  top: 44px;
  left: 50%;
}
.our-story .row:nth-child(even) .our-story-pic-block:before {
  content: "";
  position: absolute;
  transform: rotate(90deg);
  height: 100px;
  width: 2px;
  background-color:var(---primaryColor);
  top: 0px;
  z-index: -1;
}
.our-story .row:nth-child(even) .our-story-pic-block:before {
  left: 55%;
}
.our-story .row:nth-child(even) .our-story-pic-block:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  border: 2px solid var(---primaryColor);
  background-color: #fff;
  z-index: 3;
}
.our-story .row:nth-child(even) .our-story-pic-block:after {
  left: 49%;
  top: 35px;
}
@media only screen and (max-width: 992px) {
  .our-story .row:nth-child(even) .our-story-pic-block:before {
    left: 56%;
  }
  .our-story .row:nth-child(even) .our-story-pic-block:after {
    left: 48%;
  }
  .our-story .row:nth-child(even) .notification-animate:after {
    left: 49%;
  }
}
@media only screen and (max-width: 767px) {
  .our-story .row:nth-child(even) .our-story-pic {
    margin-right: 0px;
    margin-left: 50px;
  }
  .our-story:after {
    left: 20px;
  }

  .our-story .row:nth-child(even) .our-story-pic-block:after {
    left: 20px;
    right: auto;
  }

  .our-story .row:nth-child(even) .our-story-pic-block:before {
    left: 80px;
  }
  .our-story .row:nth-child(even) .notification-animate:before {
    right: auto;
    left: 22px;
  }
  .our-story .row:nth-child(even) .notification-animate:after {
    right: auto;
    left: 30px;
  }
  .our-story .row:nth-child(even) .our-story-detail{
    margin-left: 46px;
    text-align: left;
  }
  .story-right-img {
    width: 100%;
    max-width: 170px;
    margin-left:50px;
    margin-right: auto;
  }
}




/* ========== new About ==== */
.exp-box {
  position: absolute;
  left: 135px;
  background: rgba(0, 0, 0, 0.7);
  width: 260px;
  text-align: center;
  padding: 36px 15px 33px;
}
.exp-box .title {
  font-family: var(---HeadFont);
  color: #fff;
  margin-bottom: 9px;
}
.exp-box .text {
  color: #fff;
  font-family: var(---HeadFont);
  line-height: 24px;
}
.about-section {
  position: relative;
}
/* .about-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 710px;
  height: 544px;
  background-image: url(../img/bg-1.png);
} */
.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}

.about-section .image-column .inner-column {
  position: relative;
  padding-left: 20px;
  padding-bottom: 82px;
}
.about-section .image-column .inner-column .icon-dots {
  position: absolute;
  left: -120px;
  bottom: -30px;
}
.about-section .image-column .image-1 {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  margin-bottom: 0px;
}
.about-section .image-column .image-1::after {
  content: "";
  position: absolute;
  top: 0;
  left: -40px;
  width: 30px;
  height: 250px;
}
.about-section .image-column .image-1 img {
  width: 100%;
}
.about-section .image-column .image-2 {
  position: absolute;
  right: 18px;
  bottom: 41px;
  margin-bottom: 0;
  border: 15px solid #ffff;
  overflow: hidden;
}
.about-section .image-column .image-5 {
  position: absolute;
  bottom: 30px;
  left: -70px;
  z-index: -1;
}
.about-section .image-column .image-4 {
  position: absolute;
  bottom: 65px;
  left: -97px;
  z-index: -1;
}
.about-section .image-column .btn-box {
  position: absolute;
  top: 79px;
  right: 25px;
  height: 133px;
  width: 133px;
  text-align: center;
  line-height: 133px;
}
.about-section .image-column .btn-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid #d4d4d5;
  height: 100%;
}
.about-section .image-column .btn-box .play-now-two {
  width: 107px;
  height: 107px;
  line-height: 107px;
  text-align: center;
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/bg-1.png");
  color: #fff;
  font-size: 22px;
  display: inline-block;
  position: relative;
}
.about-section .exp-box {
  top: 213px;
  left: 20%;
}

@media all and (max-width: 450px) {
  .about-section .image-column .image-2 {
    position: absolute;
    right: -12px;
    bottom: 41px;
    margin-bottom: 0;
    border: 15px solid var(---primaryColor);
    overflow: hidden;
  }
  .about-section .exp-box {
    top: 193px;
    left: 21%;
  }
  .about-section .image-column .btn-box {
    position: absolute;
    top: 35px;
    right: 25px;
    height: 133px;
    width: 133px;
    text-align: center;
    line-height: 133px;
  }
}
/* ========= About New ===== */
.AboutImg{
  width: 100%;
  height: 100%;
  max-height: 300px;
  border-radius: 5px;
}
.AboutImg img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 0 7px gray;
}
.AboutTxt{
  margin-top: 30px;
}

.AboutTxt1{
  display: none;
}

@media all and (max-width: 990px) {
  .sec-title{
    margin-top: 20px;
  }
  .AboutTxt{
    display: none;
  }
  .AboutTxt1{
    display: block;
  }
}

/* ======== */

.sec-title .sub-title {
  position: relative;
  font-size: 18px;
  color: grey;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 14px;
  font-family: var(---HeadFont);
  letter-spacing: 4px;
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 26px;
  color: var(---primaryColor);
  font-family: var(---header);
  font-weight: 600;
  letter-spacing: 3px;
}

.about-section .content-column .sec-title .text {
  margin-top: 35px;
}

.about-section .outer-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.info-block {
  position: relative;
  margin-bottom: 52px;
  margin-right: 115px;
}

.info-block .inner {
  position: relative;
  padding-left: 94px;
  min-height: 80px;
}

.about-section .content-column .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact-info {
  position: relative;
  padding-left: 72px;
  top: -2px;
}
.about-section .content-column .btn-box .theme-btn {
  margin-right: 42px;
  border: 0;
  background-color: var(---SecondColor);
  color: #fff;
  text-transform: uppercase;
  padding: 17px 42px 18px;
}

.aboutIcons {
  margin: 30px 0px 30px 0px;
}
.aboutIcons .col-3 {
  margin-right: 0px;
  padding-right: 0px;
}
.aboutIcons .col-9 {
  margin: 0px;
  padding: 0px;
}

.icon-box i {
  color: var(---SecondColor);
  font-size: 35px;
  margin: 10px 10px 0px 0px;
}

.icon-txt h5 {
  font-family: var(---HeadFont);
  font-size: 20px;
  /* font-weight: 600; */
  margin-top: 10px;
}

.About_Btn1 a {
  border: 1px solid #111;
  padding: 12px 15px;
  color: var(---primaryColor);
  font-size: 17px;
  font-family: var(---HeadFont);
  font-weight: 600;
  border-radius: 5px;
}
.About_Btn2 h5 {
  color: var(---primaryColor);
  font-size: 18px;
  font-family: var(---HeadFont);
  margin-bottom: 0px;
}
.About_Btn2 a {
  color: var(---primaryColor);
  font-size: 20px;
  font-family: var(---HeadFont);
}

@media all and (max-width: 450px) {
  .About_Btn1 a {
    border: 1px solid #111;
    padding: 12px 15px;
    color: var(---primaryColor);
    font-size: 15px;
    font-family: var(---HeadFont);
    font-weight: 600;
    border-radius: 5px;
  }
  .About_Btn2 h5 {
    margin-top: -8px;
    font-size: 18px;
  }
  .About_Btn2 a {
    color: var(---primaryColor);
    font-size: 16px;
    font-family: var(---HeadFont);
  }
  .sec-title h2 {
    font-size: 24px;
    font-weight: 500;
  }
}

@media all and (max-width: 320px) {
  .about-section .content-column {
    margin-top: 60px;
  }
}
