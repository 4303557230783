/* ===================
site title css
====================== */
.site-heading {
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
}
.site-title {
  text-transform: capitalize;
  font-size: 32px;
  color: var(---primaryColor);
  margin-top: 8px;
  margin-bottom: 0;
  font-family: var(---HeadFont);
}

.header-img-divider {
  width: 140px;
  margin: 0 auto;
  margin-top: 0px;
}
.header-img-divider img {
  width: 100%;
  height: 100%;
}

.heading-divider {
  display: inline-block;
  position: relative;
  height: 3px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: var(---primaryColor);
  width: 90px;
  overflow: hidden;
}

.heading-divider:after {
  content: "";
  position: absolute;
  left: 0;
  top: -1.1px;
  height: 7px;
  width: 8px;
  background-color: #ffffff;
  -webkit-animation: heading-move 5s infinite linear;
  animation: heading-move 5s infinite linear;
}
.site-heading h4 {
  font-size: 34px;
  font-family: var(---header);
  color: grey;
}

@media all and (max-width: 992px) {
  .shadow-title {
    font-size: 100px;
  }
  .site-title {
    text-transform: capitalize;
    font-size: 26px;
    color: var(---SecondColor);
    margin-top: 8px;
    margin-bottom: 0;
  }
  .site-heading h4 {
    font-size: 26px;
    font-family: var(---header);
    color: var(---SecondColor);
    letter-spacing: 2px;
  }
}
/* ============ Common Css ============= */
.headTitle {
  padding-bottom: 30px;
  position: relative;
}
.headTitle h2 {
  text-transform: capitalize;
  font-family: var(---HeadFont);
  color: var(---primaryColor);
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  padding-bottom: 10px;
}

.headTitle h2:before {
  border-bottom: 2px solid var(---primaryColor);
  content: "";
  font-family: var(---HeadFont);
  top: 85px;
  position: absolute;
  text-align: center;
  width: 100px;
  left: 50%;
  transform: translate(-50%, -25px);
}
.headTitle h6 {
  font-family: var(---header);
  margin-top: 0px;
  text-align: center;
  padding: 0px 80px;
  font-size: 40px;
  color: var(---SecondColor);
}
.headTitle p {
  font-family: var(---SubHeading);
  margin-top: 20px;
  text-align: center;
  padding: 0px 80px;
}
.swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 20px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---primaryColor);
}
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 20px !important;
  height: 50px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---primaryColor);
}
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: #ffff !important;
}
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  color: #ffff !important;
  line-height: 1;
}

@media (max-width: 980px) {
  .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 15px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---primaryColor);
  }
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 15px !important;
    height: 30px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---primaryColor);
  }
  .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #ffff !important;
  }
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    color: #ffff !important;
    line-height: 1;
  }
}

@media (max-width: 980px) {
  .headTitle h2 {
    font-size: 26px;
  }
  .headTitle p {
    padding: 0px 0px;
    text-align: justify;
  }
}
/* ============= Banner Section ========== */
.homeBanner_Txt {
  text-align: center;
  margin-top: 80px;
}

.homeBanner_Txt h1 {
  text-align: center;
  font-size: 50px;
  font-family: var(---header);
  letter-spacing: 3px;
  color: #fff;
}
.homeBanner_Txt h2 {
  text-align: center;
  font-size: 50px;
  font-family: var(---header);
  color: #fff;
}
.homeBanner_Txt p {
  text-align: center;
  color: #fff;
  font-size: 24px;
}
.home_Banner1 {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url("../img/baanner/home1.jpeg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite alternate;
}

.home_Banner2 {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url("../img/baanner/room.jpeg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite alternate ease-in-out;
}
.home_Banner3 {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url("../img/baanner/home3.jpeg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite alternate;
}

@keyframes shrink {
  0% {
    background-size: 115% 115%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 990px) {
  .homeBanner_Txt {
    text-align: left;
  }
  .homeBanner_Txt h1 {
    font-size: 24px;
    font-family: var(---HeadFont);
    color: #fff;
  }
  .homeBanner_Txt h2 {
    font-size: 24px;
    font-family: var(---HeadFont);
    color: #fff;
  }
  .homeBanner_Txt p {
    color: #fff;
    padding: 0px 10px 0px 10px;
    font-size: 16px;
  }

}


@media (max-width: 576px) {
  .home_Banner1 {
    height: 50vh;
  }
  .home_Banner2 {
    height: 50vh;
  }
  .home_Banner3 {
    height: 50vh;
  }
}

/* ============ About Section ======= */
.aboutSection .col-md-6 {
  min-height: 450px;
}
.aboutPageImg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 400px;
}

.aboutPageImg img {
  width: 100%;
  height: 100%;
  max-width: 93%;
  max-height: 450px;
  transform: translate(40px, 40px);
  border-top: 40px solid #fff;
  border-left: 40px solid #fff;
  border-radius: 10px;
}


.about_txt h2 {
  font-size: 28px;
  font-family: var(---HeadFont);
}

.aboutSection a {
  margin-top: 1.5em;
  padding: 0.65em 1.9em;
}

.res-btn {
  border: none;
  outline: none;
  border: 1px solid #111;
  font-size: 14px;
  line-height: 28px;
  color: var(---primaryColor);
  text-transform: uppercase;
  font-family: var(---HeadFont);
  font-weight: 600;
  display: inline-block;
  border-radius: 3px;
  transition: all 0.5s ease;
  padding: 5px 20px;
}

.res-btn:hover {
  background: var(---primaryColor);
  color: #fff;
}

.exp-btn {
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 28px;
  color: var(---primaryColor);
  text-transform: uppercase;
  font-family: var(---HeadFont);
  font-weight: 600;
  display: inline-block;
  border-radius: 3px;
  transition: all 0.5s ease;
  padding: 5px 20px;
  background-color: #fff;
}

@media (max-width: 990px) {
  .aboutPageImg img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 350px;
    transform: translate(25px, 25px);
    border-top: 30px solid #fff;
    border-left: 30px solid #fff;
    border-radius: 10px;
  }
  .aboutPageImg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 300px;
  }
  .aboutPageText h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 767px) {
  .aboutPageSection .col-md-6 {
    min-height: 350px;
  }
}

@media (max-width: 500px) {
  .aboutPageImg img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 300px;
    transform: translate(20px, 20px);
    border-top: 15px solid #fff;
    border-left: 15px solid #fff;
    border-radius: 10px;
  }
  .aboutPageText h2 {
    font-size: 2rem;
  }
}

@media (max-width: 575px) {
  .aboutPageImg img {
    width: 100%;
    height: 100%;
    max-width: 95%;
    max-height: 300px;
    transform: translate(20px, 20px);
    border-top: 15px solid #fff;
    border-left: 15px solid #fff;
    border-radius: 10px;
  }

  .aboutSection .col-md-6 {
    min-height: 360px;
  }
}



.aboutImg .home-about{
  position: relative;
  padding-left: 20px;
  padding-bottom: 82px;
}
.aboutImg .img1 {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  margin-bottom: 0px;
  width: 100%;
  height: 400px;
}
.aboutImg .img1 img{
  width: 100%;
height: 100%;
}

.home-about .img2{
  position: absolute;
  /* right: 18px; */
  bottom: 41px;
  margin-bottom: 0;
  border: 10px solid #ffff;
  overflow: hidden;
  width: 200px;
  height: 225px;
}

.aboutImg .img2 img{
  width: 100%;
height: 100%;
}


@media all and (max-width: 450px) {
  .aboutImg .img1{
    width: 100%;
    height: 100%;
  }
  .aboutImg .img2 {
    position: absolute;
    /* right: -12px; */
    bottom: 41px;
    margin-bottom: 0;
    border: 10px solid #fff;
    overflow: hidden;
    width: 150px;
  height: 175px;
  }
}

@media all and (max-width: 310px) {
  .aboutImg .img2 {
    display: none;
  }
}

/* ========= AminitieSection ============ */
.aminitiesSection {
  background-color: #f8f5f0;
}

.aminitiCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.aminitiCard span {
  padding: 12px;
  font-size: 30px;
  color: var(---SecondColor);
  font-weight: 500;
  margin: 10px 0;
  border-radius: 50%;
  box-shadow: 0 0 4px gray;
}
.aminitiCard span:hover {
  padding: 12px;
  font-size: 30px;
  color: #fff;
  font-weight: 500;
  margin: 10px 0;
  border-radius: 50%;
  box-shadow: 0 0 4px gray;
  background-color: var(---SecondColor);
  transition: all 0.5s ease-in-out;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.aminitiCard h3 {
  font-size: 16px;
  color: #111;
  font-weight: normal;
  text-transform: capitalize;
  text-align: center;
  margin: 10px 0;
  font-family: var(---HeadFont);
}

@media (max-width: 500px) {
  .aminitiCard span {
    font-size: 20px;
  }
  .aminitiCard h3 {
    font-size: 14px;
  }
}

/* =============== Near by Attraction ============== */
.nearbyAttrection .attrectionCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 5px 7px #808080;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  max-height: 250px;
}

.attrectionImg {
  width: 100%;
  height: 100%;
}

.attrectionImg img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  min-width: 350px;
  min-height: 350px;
}

.attrectionImg h3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding: 10px 15px;
  background-color: rgb(0, 0, 0, 0.5);
  margin: 0;
  text-align: center;
  transition: 0.5s ease-in-out;
  color: #fff;
  font-size: 26px;
  font-family: var(---header);
  letter-spacing: 3px;
}

.attractionBody {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(0, 0, 0, 0.5);
  bottom: -100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  padding: 10px;
}

.attractionBody h3 {
  text-align: center;
  font-weight: 500;
  color: #fff;
  font-size: 26px;
  font-family: var(---header);
}

.attractionBody p {
  text-align: center;
  color: #eee;
}

.attrectionCard:hover .attrectionImg h3 {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.attrectionCard:hover .attractionBody {
  bottom: 0;
  transition: 0.5s ease-in-out;
  opacity: 1;
}

@media (max-width: 992px) {
  .attrectionCard {
    max-height: 220px;
    margin: 8px 8px 20px 8px;
  }
}

@media (max-width: 500px) {
  .attrectionCard {
    max-height: 220px;
    margin: 8px 8px 20px 8px;
  }

  .attractionBody p {
    text-align: justify;
    color: #eee;
  }
}

/* ========= Resort Section ============ */
.resot-activities {
  background-color: #f8f5f0;
}

.resortHead h2 {
  font-size: 28px;
  font-family: var(---HeadFont);
}
.resortPara p {
  color: #808080;
}

.wel-box {
  display: block;
  height: 170px;
  padding-bottom: 0;
  background: #fff;
  padding: 2em 0 3em;
  position: relative;
}
.wel-box .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(---SecondColor);
  padding: 10px;
  transform: scale(0, 0);
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
}

.wel-box .overlay .icon_position_table {
  border: 1px dashed rgba(236, 236, 236, 0.5);
  display: -webkit-flex;
  display: flex;
  height: 100%;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.wel-box .overlay .icon_position_table .icon_container h2 {
  text-align: center;
  font-size: 20px;
  font-family: var(---HeadFont);
  color: #fff;
  font-weight: 500;
  margin: 0;
  padding: 0 0 0.5em;
  font-style: italic;
}

.wel-box .overlay .icon_position_table .icon_container p {
  text-align: center;
  color: #fffefe;
  margin: 0;
  padding: 0;
}

.single_wel_cont:hover .overlay,
.single_wel_cont:focus .overlay {
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
}

.wel-box:before {
  content: "";
  position: absolute;
  border: 1px dashed #d9d9d9;
  top: 7px;
  left: 10px;
  right: 10px;
  bottom: 7px;
}

.wel-box .icon-box {
  text-align: center;
}
.icon-box i {
  font-size: 30px;
  color: var(---SecondColor);
}

.wel-box h4 {
  color: #0e1322;
  text-align: center;
  padding: 0;
  font-family: var(---HeadFont);
  text-transform: capitalize;
  font-size: 20px;
}

.resot-activities .single_wel_cont {
  padding: 15px;
}

/* ========== Room Section ============ */
.room_List {
  /* border: 1px solid #111; */
  padding: 10px;
  box-shadow: 0 0 5px grey;
}
.room_Img {
  width: 100%;
  height: 100%;
  max-height: 200px;
}
.room_Img img {
  width: 100%;
  height: 100%;
  max-height: 200px;
}
.room_Desc {
  padding: 10px 0px;
}
.room_Desc h2 {
  font-size: 26px;
  font-family: var(---HeadFont);
}
.room_Desc p {
  font-family: var(---SubHeading);
}

.room-btn {
  border: none;
  outline: none;
  border: 1px solid #111;
  font-size: 14px;
  line-height: 24px;
  color: var(---primaryColor);
  text-transform: uppercase;
  font-family: var(---HeadFont);
  display: inline-block;
  border-radius: 3px;
  transition: all 0.5s ease;
  padding: 7px 15px;
  font-weight: 600;
}

.room-btn:hover {
  background: var(---primaryColor);
  color: #fff;
  font-weight: 500;
}

@media (max-width: 992px) {
  .room_List {
    margin-bottom: 20px;
  }
}

/* ========= new Room ========= */
.home_Room {
  width: 100%;
  height: 100%;
}
.home_Room img {
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: 300px;
}
.home_roomDesc {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 400px;
  max-height: 300px;
}

.home_roomTxt h2 {
  font-size: 26px;
  font-family: var(---HeadFont);
  font-weight: 600;
  color: var(---primaryColor);
}
.home_roomBtn {
  border: 1px solid #111;
  font-size: 14px;
  color: #111;
  text-transform: capitalize;
  font-family: var(---HeadFont);
  display: inline-block;
  border-radius: 3px;
  transition: all 0.5s ease;
  padding: 7px 20px;
}
.home_roomBtn:hover {
  border: none;
  font-size: 14px;
  color: #fff;
  text-transform: capitalize;
  font-family: var(---HeadFont);
  border-radius: 3px;
  transition: all 0.5s ease;
  padding: 7px 20px;
  background-color: var(---primaryColor);
}

/* ========== Counter Section =========== */
.counter_txt h2 {
  color: #fff;
  font-family: var(---header);
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 3px;
}

.counter_txt p {
  color: #fff;
  font-family: var(---HeadFont);
}
.counter-section {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/baanner/2.webp");
  height: 65vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
}
.counter.left_icn {
  padding-left: 73px;
  position: relative;
}
.counter {
  color: #fff;
  font-family: var(---HeadFont);
  min-height: 60px;
  position: relative;
}
.counter.left_icn i {
  bottom: auto;
  color: #fff;
  left: 0;
  position: absolute;
  top: 3px;
  background-color: var(---SecondColor);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-white .counter i {
  color: var(---primaryColor);
}
.counter i,
.counter i:before {
  font-size: 35px;
}
.counter i {
  display: inline-block;
  line-height: 64px;
}
.counter span {
  display: block;
  font-size: 44px;
  font-weight: 500;
  line-height: 40px;
  margin: 10px 0 5px;
}
.counter label {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0 0;
  position: relative;
  text-transform: uppercase;
}

.count-btn {
  border: none;
  outline: none;
  border: 1px solid #fff;
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  text-transform: uppercase;
  font-family: var(---HeadFont);
  display: inline-block;
  border-radius: 3px;
  transition: all 0.5s ease;
  padding: 7px 15px;
}

.count-btn:hover {
  background: var(---primaryColor);
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .counter span {
    font-size: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 992px) {
  .counter-section {
    height: auto;
  }
  .counter.left_icn {
    padding-left: 60px;
    position: relative;
  }
  .counter {
    color: #fff;
    font-family: var(---HeadFont);
    min-height: 40px;
    position: relative;
  }
  .counter.left_icn i {
    bottom: auto;
    left: 0;
    line-height: 60px;
    position: absolute;
    top: 5px;
  }
  .counter i,
  .counter i:before {
    font-size: 30px;
  }
  .counter i {
    display: inline-block;
    line-height: 42px;
  }
  .counter span {
    display: block;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    margin: 10px 0 5px 2px;
  }
  .counter label {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin: 0px 0 0 10px;
    position: relative;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 502px) {
  .counter label {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 0 5px;
    position: relative;
    text-transform: uppercase;
  }
}
/* ============ Testimonal Section ========= */
.testimonalSection {
  background-color: #f8f5f0;
}
.testimonalSection .item .test-cont {
  background: #fff;
  padding: 2em;
  border-radius: 8px;
  position: relative;
  display: block;
}

.testimonalSection .item .test-cont:after {
  width: 0;
  height: 0;
  border-left: 1px solid transparent;
  border-right: 20px solid transparent;
  border-top: 26px solid #fff;
  position: absolute;
  content: "";
  bottom: -26px;
}

.testimonalSection .item .test-cont p {
  color: #111;
  font-family: var(---SubHeading);
}

.testimonalSection .item .test-bot {
  padding: 25px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.client_img {
  margin-right: 15px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.client_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.testimonalSection .item .test-bot .client_name {
  float: left;
  margin: 1em 0 0;
}

.testimonalSection .item .test-bot .client_name h5 {
  color: #111;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-family: var(---HeadFont);
}

.testimonalSection .item .test-bot .client_name h5 span {
  color: #3d3d3d;
  font-family: var(---HeadFont);
  font-size: 18px;
}

.testimonalSection .item .test-bot .client_name ul {
  margin: 0px;
  padding: 0px;
}
.testimonalSection .item .test-bot .client_name ul li {
  display: inline-block;
  margin: 2px -1px 0 1px;
}

.testimonalSection .item .test-bot .client_name ul li i {
  color: #707d93;
  font-size: 1em;
  margin: 0 8px 0 0;
}

.testimonial-sec-p {
  padding: 6em 1em 1em;
  border-bottom: 1px solid #e4e4e4;
}

.testimonial-p .media-body h3 {
  font: 2em/1em "Playball", sans-serif;
  letter-spacing: 0.3px;
  color: #242424;
  margin: 0 0 0.5em;
  padding: 0;
}

.testimonial-p .media-body p {
  font: 400 1.0715em/1.8em "Open Sans", sans-serif;
  letter-spacing: 0.3px;
  color: #898989;
  margin-bottom: 1em;
}

.testimonial-p .media-body a {
  font: 600 1.0715em/1em "Open Sans", sans-serif;
  letter-spacing: 0.3px;
  color: #1f4d84;
  font-style: italic;
  text-align: left;
  display: block;
}

.testimonial-p .media-body a span {
  color: #3d3d3d;
  font: 400 0.9em/1em "Open Sans", sans-serif;
}

.testimonial-p .media-body h6 {
  text-align: right;
  display: block;
}

.testimonial-p .media-left {
  padding-right: 60px;
}

.testimonial-p .media-left a img {
  border-left: 4px solid var(---primaryColor);
  border-right: 1px solid #e1e1e1;
  border-top: 2px solid var(---primaryColor);
  border-bottom: 1px solid #e1e1e1;
}

.testimonial-p .m-right {
  float: right;
  padding-right: 0;
  padding-left: 60px;
}

.testimonial-p .m-left {
  float: left;
  width: 82%;
}

.testimonial-p .l-sign {
  float: left;
}

.common-pad-test {
  padding-top: 0em;
  padding-bottom: 6.4em;
}

@media (max-width: 479px) {
  .testimonial-p .media-left {
    display: block;
    text-align: center;
    padding-right: 0;
  }

  .testimonial-p .media-body h3 {
    padding: 1em 0 0;
  }
}

/* =============== Near by Attraction ============== */
.gallerCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 5px 7px #808080;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  max-height: 250px;
}

.gallerImg {
  width: 100%;
  height: 100%;
}

.gallerImg img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  min-width: 350px;
  min-height: 350px;
}

.gallerImg h3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding: 10px 15px;
  background-color: rgb(0, 0, 0, 0.5);
  margin: 0;
  text-align: center;
  transition: 0.5s ease-in-out;
  color: #fff;
  font-size: 26px;
  font-family: var(---header);
  letter-spacing: 3px;
}

.gallerBody {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(0, 0, 0, 0.5);
  bottom: -100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  padding: 10px;
}

.gallerBody h3 {
  text-align: center;
  font-weight: 500;
  color: #fff;
  font-size: 26px;
}

.gallerBody p {
  text-align: center;
  color: #eee;
}

.gallerCard:hover .gallerImg h3 {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.gallerCard:hover .gallerBody {
  bottom: 0;
  transition: 0.5s ease-in-out;
  opacity: 1;
}

@media (max-width: 992px) {
  .gallerCard {
    max-height: 220px;
    margin: 8px 8px 20px 8px;
  }
}

@media (max-width: 500px) {
  .gallerCard {
    max-height: 220px;
    margin: 8px 8px 20px 8px;
  }

  .gallerBody p {
    text-align: justify;
    color: #eee;
  }
}

/* ============== Form section ====== */
.booking-section {
  position: relative;
  padding: 110px 0 107px;
  margin-top: 100px;
}
.booking-section:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  background-image: url(../img/bg-booking.jpg);
  background-repeat: no-repeat;
}

.booking-column .booking-left {
  padding: 30px;
  position: relative;
  z-index: 9;
  margin-top: -170px;
  border-top: 10px solid var(---primaryColor);
}

.booking-column .booking-left:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f8f5f0;
  z-index: 9;
  height: 100%;
}
.booking-column .booking-left .booking-left-Txt {
  margin-bottom: 54px;
  position: relative;
  z-index: 9;
}
.booking-left-Txt h2 {
  color: var(---primaryColor);
  font-family: var(---HeadFont);
}

.booking-column .booking-left .booking-form {
  position: relative;
  z-index: 9;
}
.booking-column .booking-left .booking-form label {
  color: #111;
  font-size: 14px;
  font-family: var(---HeadFont);
  width: 100%;
}
.booking-column .booking-left .booking-form .form-control {
  border-radius: 0px;
}
.booking-column .booking-left .booking-form .error-text {
  color: red;
  font-family: var(---HeadFont);
  font-size: 12px;
}
.booking-column .booking-left .booking-form button{
  font-size: 20px;
  color: #fff;
  background-color: var(---primaryColor);
  padding: 5px 12px;
  border: none;
  color: white;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: var(---HeadFont);
}


.booking-content .booking-right {
  padding-left: 10px;
  position: relative;
}
.booking-content .booking-right .bookingsec-title span {
  color: #fff;
  font-size: 16px;
  font-family: var(---HeadFont);
  padding-bottom: 10px;
  letter-spacing: 7.4px;
}
.booking-content .booking-right .bookingsec-title h2 {
  margin-bottom: 30px;
  color:white;
  font-size: 30px;
  font-family: var(---HeadFont);
}

.booking-content .booking-right h4{
  color:#eee;
  font-family: var(---SubHeading);
}

.booking-content .booking-right p {
  color: #fff;
  font-size: 16px;
  font-family: var(---SubHeading);
}

.booking-content .booking-right p a{
  color: #fff;
  font-size: 16px;
  font-family: var(---SubHeading);
}

@media (max-width: 992px) {
  .booking-section {
    padding: 30px 0px;
    margin-top: 50px;
  }
  .booking-column .booking-left {
    margin-top: 0px;
  }
  .contactsec-title {
    position: relative;
    margin: 10px 0px 10px 0px;
  }
  .booking-content .booking-right .bookingsec-title {
   margin-top:30px;
  }
  .booking-content .booking-right .bookingsec-title span {
    letter-spacing: 2px;
    color: #111;
  }
  .booking-content .booking-right .bookingsec-title h2 {
    margin-bottom: 10px;
    color: var(---primaryColor);
    font-size: 24px;
    font-family: var(---HeadFont);
  }

.booking-content .booking-right h4{
  color:var(---SecondColor);
  font-family: var(---SubHeading);
}

.booking-content .booking-right p {
  color:#111;
  font-size: 16px;
  font-family: var(---SubHeading);
}

.booking-content .booking-right p a{
  color:#111;
  font-size: 16px;
  font-family: var(---SubHeading);
}
  .react-datepicker-wrapper{
    width: 100%;
  }
}
