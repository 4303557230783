.Blog-banner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url("../img/blog-bg.jpg");
  height: 80vh;
  background-size:cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: shrink 4s infinite alternate ease-in-out; */
}
.BlogBanner-Txt {
  margin-top: 100px;
}

.BlogBanner-Txt h2 {
  font-size: 50px;
  font-family: var(---header);
  color: #fff;
  text-align: center;
}
.BlogBanner-Txt p {
  text-align: center;
  color: #fff;
  font-size: 24px;
}

@media (max-width: 990px) {
  .BlogBanner_Txt {
    margin-top: 40px;
  }
  .BlogBanner-Txt h2 {
    font-size: 24px;
    font-family: var(---HeadFont);
    color: #fff;
    text-align: center;
  }
  .BlogBanner-Txt p {
    color: #fff;
    padding: 0px 10px 0px 10px;
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .Blog-banner {
    height: 50vh;
  }
}

/* ======= Blog Detail Section ==== */
.blog-head {
  padding: 10px 0px 20px 0px;
}
.blog-head h2 {
  font-family: var(---HeadFont);
  font-size: 35px;
  font-weight: 600;
  color: var(---primaryColor);
}
.blog-head h6 {
  font-family: var(---HeadFont);
  color: gray;
}
.blog-head h6 span {
  margin-left: 10px;
  font-family: var(---HeadFont);
  color: gray;
}
.blog-head h6 span i {
  margin-right: 5px;
  color: var(---SecondColor);
}
.blog-head h4 {
  font-family: var(---HeadFont);
}
.blog-head h5 {
  font-family: var(---HeadFont);
  color: gray;
  font-size: 14px;
}

@media (max-width: 576px) {
  .blog-head h2 {
    font-family: var(---HeadFont);
    font-size: 24px;
    font-weight: 600;
    color: var(---primaryColor);
  }
  .blog-head h6 {
    font-family: var(---HeadFont);
    color: var(---primaryColor);
    font-size: 12px;
  }
  .blog-head h6 span {
    margin-left: 10px;
    font-family: var(---HeadFont);
    color: var(---primaryColor);
  }
}

.blog-img1 {
  width: 100%;
  height: 100%;
  max-height:550px;
}
.blog-img1 img {
  width: 100%;
  height: 100%;
}

.blogTxt1 p{
    font-family: var(---SubHeading);
    color: #808080;
}

.blog-img2 {
  width: 100%;
  height: 100%;
}
.blog-img2 img {
  width: 100%;
  height: 100%;
}

.blogTxt2 p{
    font-family: var(---SubHeading);
    color: #808080;
}
.blog-img3 {
  width: 100%;
  height: 100%;
  max-height: 500px;
}
.blog-img3 img {
  width: 100%;
  height: 100%;
}

.blog-head2 h3 {
  font-family: var(---HeadFont);
  color: var(---primaryColor);
}

.blogTxt3{
    font-family: var(---SubHeading);
    color: #808080;
    margin-top: 30px;
}
.blogTxt3 p{
    font-family: var(---SubHeading);
    color: #808080;
}
.blogTxt3 p span{
    font-family: var(---SubHeading);
    color:var(---primaryColor);
    font-weight: 600;
}
.blogTxt3 small{
    font-family: var(---SubHeading);
    color:var(---primaryColor);
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 20px;
}
@media (max-width: 576px) {
    .blogTxt2{
        font-family: var(---SubHeading);
        color: #808080;
        margin-top: 30px;
    }
  .blog-head2 h3 {
    font-family: var(---HeadFont);
    font-size: 24px;
    font-weight: 600;
    color: var(---primaryColor);
  }
  .blogTxt3 small{
    font-size: 18px;
  }
  .blog-img1 {
    width: 100%;
    height: 100%;
    max-height: auto;
  }
  .blog-img3 {
    width: 100%;
    height: 100%;
    max-height:auto;
  }
  .blog-img3 {
    width: 100%;
    height: 100%;
    max-height:auto;
  }
}
