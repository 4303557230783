.Gallery-banner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url("../img/baanner/gallery.jpeg");
  height: 80vh;
  background-size:cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: shrink 4s infinite alternate ease-in-out; */
}
.GalleryBanner-Txt{
  margin-top: 100px;
}

.GalleryBanner-Txt h1{
  font-size: 50px;
  font-family: var(---header);
  color: #fff;
  text-align: center;
}
.GalleryBanner-Txt p{
  text-align: center;
  color: #fff;
  font-size: 24px;
}

@media (max-width: 990px) {
  .GalleryBanner_Txt{
    margin-top: 70px;
  }
  .GalleryBanner-Txt h2{
    font-size: 24px;
    font-family: var(---HeadFont);
    color: #fff;
    text-align: center;
  }
  .GalleryBanner-Txt p{
    text-align: center;
    color: #fff;
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .Gallery-banner {
    height: 50vh;
  }
}


/* ============ Gallery Items Pages ======== */
.GalleryItems .cards{
  box-shadow: 0 0 7px grey;
  margin-bottom: 20px;
  padding:10px;
  border-radius: 5px;
}
.GalleryItem-cardImg{
  width: 100%;
  height: 100%;
}
.GalleryItem-cardImg img{
  width: 100%;
  height: 100%;
  height: 200px;
}


.GalleryItem-cardTxt h4{
  font-family: var(---HeadFont);
  color: var(---primaryColor);
  font-size: 20px;
  padding: 10px;
}
