/* =========== Footer Section ======= */
.footer {
  background-image: linear-gradient(
    120deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ),
  url("../img/baanner/2.webp");
  color:#fff;
  background-color: #242020;
  padding-top: 80px;
}
.footer-logo {
  width: 100%;
  height: 100%;
}
.footer-logo img {
  width: 100%;
  height: 100%;
  transform: scale(0.9, 1);
}
.footer-desc p {
  margin-top: 30px;
}

.footer-head {
  padding-bottom:10px;
  position: relative;
}
.footer-head h4 {
  text-transform: uppercase;
  font-family: var(---HeadFont);
  color:var(---SecondColor);
  font-size: 20px;
  padding-bottom: 0px;
  font-weight: 600;
}


/* .footer-head h4:before {
  border-bottom: 2px solid var(---primaryColor);
  content: "";
  font-family: var(---HeadFont);
  top: 65px;
  position: absolute;
  width: 50px;
  left: 30%;
  transform: translate(-50%, -30px);
} */

.footer-link ul {
  padding: 0px;
}
.footer-link ul li{
  line-height: 26px;
}

.footer-link ul li a {
  padding: 0px;
  color: #fff;
}

.footer-address ul li i{
  padding: 0px;
  color:var(---SecondColor);
  font-size:22px;
  margin-right:7px;
}
.footer-address ul{
  padding: 0px;
}

.footer-address ul li span a {
  padding: 0px;
  color: #fff;
}
/* =============== Footer CopyRight ============= */
.Footer_copyRight {
  background-color: #000;
}

.Footer_copyRight .copyright {
  text-align: left;
  padding: 20px 0px 20px 0px;
}
.Footer_copyRight .copyright p {
  font-family: var(---SubHeading);
  color: #fff;
  margin-bottom: 0px;
}
.Footer_copyRight .copyright p span{
  color:var(---SecondColor);
}

.Footer_copyRight .develope-by {
  text-align: right;
  padding: 20px 0px 20px 0px;
}
.Footer_copyRight .develope-by p {
  font-family: var(---SubHeading);
  text-align: right;
  color: #fff;
  margin-bottom: 0px;
}
.Footer_copyRight .develope-by p span{
  color:var(---SecondColor);
}
.Footer_copyRight .develope-by p a {
  text-decoration: none;
  color: #fff;
}

@media (max-width: 990px) {
  .footer {
    padding: 20px 0px;
    height: auto;
  }
  .footer-logo img {
    object-fit: contain;
    transform: scale(1, 1.2);
  }

  .footerUseful_link {
    margin-top: 20px;
  }
  .footerProperty_Types {
    margin-top: 20px;
  }
  .footerProperties_Available {
    margin-top: 20px;
  }
  .Footer-MainSection-col-2 {
    margin-top: 20px;
  }
  .Footer-icons {
    margin: 0px 5px 0px 0px;
  }
  .Footer_copyRight .develope-by {
    text-align:center;
  }
  .Footer_copyRight .develope-by p {
    text-align:center;
  }
  .Footer_copyRight .copyright {
    padding: 0px 10px 0px 10px;
  }
  .Footer_copyRight .develope-by {
    text-align: right;
    padding: 0px;
  }
}
