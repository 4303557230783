.Room-banner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url("../img/baanner/room.jpeg");
  height: 80vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: shrink 4s infinite alternate ease-in-out; */
}
.RoomBanner_Txt{
  margin-top: 100px;
}

.RoomBanner_Txt h1{
  font-size: 50px;
  font-family: var(---header);
  color: #fff;
  text-align: center;
}
.RoomBanner_Txt p{
  text-align: center;
  color: #fff;
  font-size: 24px;
}

@media (max-width: 990px) {
  .RoomBanner_Txt{
    margin-top: 70px;
  }
  .RoomBanner_Txt h2{
    font-size: 24px;
    font-family: var(---HeadFont);
    color: #fff;
    text-align: center;
  }
  .RoomBanner_Txt p{
    color: #fff;
    padding: 0px 10px 0px 10px;
    font-size: 16px;
  }
}
@media (max-width:576px) {
  .Room-banner {
    height: 50vh;
  }
}

/* ========== Room Detail Section ======== */
.roomImg-long {
  width: 100%;
  height: 100%;
}
.roomImg-long img {
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.roomImg-short {
  width: 100%;
  height: 100%;
  max-width: 240px;
  max-height: 100px;
}
.roomImg-short img {
  width: 100%;
  height: 100%;
}
.roomTxt h2 {
  font-size: 26px;
  font-family: var(---HeadFont);
  font-weight: 600;
  color: var(---primaryColor);
}

.aminities {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.aminities span {
  font-size: 32px;
  color: var(---SecondColor);
  font-weight: 500;
  margin: 10px 0;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid var(---SecondColor);
}

.aminities h5 {
  font-size: 16px;
  color: #444;
  font-weight: normal;
  text-transform: capitalize;
  text-align: center;
  margin: 10px 0;
}
.room-aminites h4{
  font-size: 24px;
  font-family: var(---HeadFont);
  color: var(---primaryColor);
}

@media (max-width: 990px) {
    .roomTxt h2 {
        margin-top: 20px;
      }
  }

/* =========== Room Contact ========= */
.room-contact{
    background-color: #eee;
}

.room-btn {
  border: none;
  outline: none;
  border: 1px solid #111;
  font-size: 14px;
  color: #111;
  text-transform: capitalize;
  font-family: var(---HeadFont);
  display: inline-block;
  border-radius: 3px;
  transition: all 0.5s ease;
  padding: 7px 20px;
}

.room-btn:hover {
  border:none;
  font-size: 14px;
  color: #fff;
  text-transform: capitalize;
  font-family: var(---HeadFont);
  border-radius: 3px;
  transition: all 0.5s ease;
  padding: 7px 20px;
  background-color: var(---primaryColor);
}

.room-contact .form-label{
color: #111;
}

.room-contact .react-datepicker-wrapper{
  width: 100%;
}