.header{
  background-color: var(---primaryColor);
  padding: 10px 0px 0px 0px;
}

.header_sociallink{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header_sociallink ul{
  display: flex;
  justify-content:center;
  align-items: center;
}
.header_sociallink ul li{
  margin-right: 20px;
}

.headerlink ul {
  padding: 0px;
}

.headerlink ul li a {
  padding: 0px;
  color: #fff;
}

.headerlink ul {
  padding: 0px;
}

.headerlink ul li a {
  padding: 0px;
  color: #fff;
}
.headerlink ul li i{
  padding: 0px;
  color:#fff;
  font-size:22px;
  margin-right:7px;
}
.socialIcon a{
  color: #fff;
}
.socialIcon:hover{
  color: #fff;
}

@media (max-width: 992px) {
  .header{
    display: none;
  }
}
/* ========= NavBar Section ========== */
.nav-bar-component {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: fit-content;
  z-index: 10000 !important;
}

.NavBar {
  background: rgba(255,255,255,0.7);
  z-index: 99;
}

.NavBar-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(247, 247, 247);
}

.navbar-nav a {
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 10px;
  text-decoration: none;
  color: var(---primaryColor);
  text-transform: uppercase;
  /* font-weight: 600; */
  font-family: var(---HeadFont);
}

.navbar-dark .navbar-nav .nav-link {
  text-align: left;
}
.navbar #collasible-nav-dropdown {
  padding: 0;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  color: var(---primaryColor);
}
.navbar-dark .navbar-nav .nav-link{
  margin-top: -7px;
  color: var(---primaryColor);
}
.navbar-dark .navbar-nav .nav-link:hover{
  color: var(---primaryColor);
}
.navbar-nav .show>.nav-link {
  color: var(---primaryColor);
}

.NavLogo {
  height: 9vh;
  width: 100%;
  transform: scale(1.2, 1.4);
}

.NavLogo img {
  height: 100%;
  width: 100%;
}
.nav-link a:hover {
  color:var(---primaryColor);
}
.navbarLarge{
  display: block;
}
.navbarSmall{
  display:none;
}



@media (max-width: 992px) {
  .NavBar {
    background: rgb(255,255,255);
    z-index: 99;
  }
  
  .Social-media-icons {
    display: none;
  }

  .navbar-nav {
    text-align: left;
  }

  .NavLogo {
    height:8vh;
    width: 100%;
    transform: scale(1.4,1.3);
  }
  .navbar-nav a {
    margin: 15px 0px 15px 0px !important;
  }
  .navbar-toggler {
    background-color: var(---primaryColor);
  }
  .navbar-dark .navbar-nav .nav-link{
    margin-top: 0px;
  }
  .navbar-Btn{
    display: none;
  } 
  .navbarLarge{
    display: none;
  }
  .navbarSmall{
    display:block;
  }
}

@media (max-width: 430px) {
  .NavLogo {
    height:8vh;
    width:100%;
    transform: scale(1.1, 1.1);
  }
}




